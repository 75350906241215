<template lang="pug">
  .jwl-box-column(:class="rootClassNames")
    template(v-if="withImage")
      common-profile-image.jwl-box-column__photo(:image-url="imageSource")
      .jwl-box-column__photo-label
        .jwl-box-column__key {{valueHeadline}}
        .jwl-box-column__value {{valueText ? valueText : $t(imageSource ? 'general.photoProvided' : 'general.photoNotProvided')}}
    template(v-else)
      .jwl-box-column__key
        | {{valueHeadline}}
      .jwl-box-column__value
        a(:href="valueLinkTarget" v-if="valueLinkTarget && valueText")
          | {{valueText}}

          small.jwl-box-column__value-muted(v-if="valueMuted")
            | ({{valueMuted}})

        template(v-else-if="valueLinkTarget && !valueText")
          | –

        template(v-else)
          | {{valueText}}

          small.jwl-box-column__value-muted(v-if="valueMuted")
            | ({{valueMuted}})
</template>

<script>
import CommonProfileImage from 'common-modules/src/components/CommonProfileImage.vue';

export default {
  name: 'sis-box-column',
  components: {
    CommonProfileImage,
  },
  props: {
    withImage: {
      type: Boolean,
      default: false,
    },
    imageSource: {
      type: String,
      required: false,
    },
    valueLinkTarget: {
      type: String,
      required: false,
    },
    valueHeadline: String,
    valueText: String,
    valueMuted: {
      type: String,
      required: false,
    },
  },
  computed: {
    rootClassNames () {
      const classes = [];

      if (this.withImage) {
        classes.push('jwl-box-column--photo');
      }

      return classes.join(' ');
    },
  },
};
</script>

<style lang="sass">
  @import "../styles/jwlBoxColumn"
</style>
