



































































import Vue from 'vue';
import Component from 'vue-class-component';
import { User } from '@/store/interfaces/User';
import BackBar from '@/components/BackBar.vue';
import JwlButton from '@/components/JwlButton.vue';
import SisBoxColumn from '@/helper/sisBoxColumn.vue';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    SisBoxColumn,
    CommonError,
    BackBar,
    JwlButton,
  },
})
export default class LmsStudentOverview extends Vue {
  fetching = true;
  error = null;
  user: User|null = null;

  get studentName (): string {
    const { user } = this;
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }
    return 'Name not found';
  }

  get birthDate (): string {
    if (this.user?.birthDate) {
      const date = new Date(this.user.birthDate);
      return date.toLocaleDateString();
    }

    return '-';
  }

  mounted (): void {
    this.$store.dispatch('getData', `student/detail/${this.$route.params.student}`)
      .then((data) => {
        this.user = data.user;
        this.fetching = false;
      })
      .catch((e) => {
        this.error = e;
      });
  }
}
